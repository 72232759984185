<template>
  <div class="category f12">
    <div class="top">
      <p >药品筛选</p>
      <p class="txt">
        （当前条件下共 <span class="red">{{ total }}</span> 个药品）
      </p>
      <div class="fold" @click="showCate = !showCate">
        <div class="more_box" v-if="!showCate">
          <span>更多</span>
          <div class="arrow">
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <div class="more_box" v-else>
          <span>收起</span>
          <div class="arrow">
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
    </div>

    <div v-show="showCate">
      <div class="cate_wrap" v-for="item in list" :key="item.key">
        <p>{{ item.title }}：</p>
        <div class="right" :class="item.showMore ? 'nomore' : ''">
          <div class="cate_item" v-for="sub in item.list" :key="sub.value" :class="[sub.sel == 101 ? 'active' : '']" @click="clickItem(sub, item.key)">
            {{ sub.name }}
          </div>
        </div>
        <div @click="item.showMore = !item.showMore" v-if="item.fold">
          <div class="more_box" v-if="item.showMore">
            <span>更多</span>
            <div class="arrow">
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="more_box" v-else>
            <span>收起</span>
            <div class="arrow">
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="cate_wrap">
        <p>厂家筛选：</p>
        <div class="flex_1">
          <div class="flex_row">
            <el-input placeholder="搜索厂家名称" suffix-icon="el-icon-search" v-model="p.scqy_key" @change="getScqy"> </el-input>
            <span class="str" @click="clear('scqy_key')">不限</span>
          </div>
          <div class="flex_1 flex_sp">
            <div class="right" :class="isFold1 ? 'nomore' : 'maxheight'">
              <div class="cate_item shenglue_1" v-for="item in list6" :key="item.id" @click="handleScqy(item)">
                {{ item }}
              </div>
            </div>

            <div v-if="showMore1" @click="isFold1 = !isFold1">
              <div class="more_box" v-if="isFold1">
                <span>更多</span>
                <div class="arrow">
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="more_box" v-else>
                <span>收起</span>
                <div class="arrow">
                  <i class="el-icon-arrow-up"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cate_wrap">
        <p>规格筛选：</p>
        <div class="flex_1">
          <div class="flex_row">
            <el-input placeholder="搜索规格名称" suffix-icon="el-icon-search" v-model="p.sku_key" @change="getSku"> </el-input>
            <span class="str" @click="clear('sku_key')">不限</span>
          </div>
          <div class="flex_1 flex_sp">
            <div class="right" :class="isFold2 ? 'nomore' : 'maxheight'">
              <div class="cate_item shenglue_1" v-for="item in list4" :key="item.id" @click="handleSku(item)">
                {{ item }}
              </div>
            </div>

            <div v-if="showMore2" @click="isFold2 = !isFold2">
              <div class="more_box" v-if="isFold2">
                <span>更多</span>
                <div class="arrow">
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="more_box" v-else>
                <span>收起</span>
                <div class="arrow">
                  <i class="el-icon-arrow-up"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="another">
      <p >综合排序：</p>
      <div class="flex_1 flex_sp_c">
        <div class="flex_row">
          <div class="another_item" v-for="item in list5" :key="item.id" @click="handleSort(item.id)" :class="item.id == p.order_key ? 'active' : ''">
            <span>{{ item.name }}</span>
            <i v-if="item.order_desc == 101" class="el-icon-top"></i>
            <i v-else class="el-icon-bottom"></i>
          </div>
          <!-- <div class="flex_row_aic">
            <span class="mr_10">只显示有货</span>
            <el-switch v-model="value" active-color="#0abc64"> </el-switch>
          </div> -->
        </div>
        <div class="flex_row_aic c9">
          <div class="type_item flex_c_c" :class="{ active: currentType == 101 }" @click="handleType(101)">
            <img v-if="currentType == 101" class="img" src="@/assets/img/goods/sel_big.png" alt="" />
            <img v-else class="img" src="@/assets/img/goods/selnor_big.png" alt="" />
            <span>大图</span>
          </div>
          <div class="type_item flex_c_c" :class="{ active: currentType == 102 }" @click="handleType(102)">
            <img v-if="currentType == 102" class="img" src="@/assets/img/goods/sel_list.png" alt="" />
            <img v-else class="img" src="@/assets/img/goods/selnor_list.png" alt="" />
            <span>列表</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DeepClone, getTextWidth } from "@/utils/tool";
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showCate: false,
      isFold1: true,
      isFold2: true,
      showMore1: true,
      showMore2: true,
      textHeight: "",
      value: true,
      currentType: 101, //101大图 102列表
      list: [],
      list4: [],
      list5: [
        { id: 101, name: "综合", order_desc: 101 },
        { id: 103, name: "价格", order_desc: 101 },
        { id: 104, name: "库存", order_desc: 101 },
        { id: 102, name: "销量", order_desc: 101 },
      ],
      list6: [],
      p: {
        kw: "", //商品搜索框值
        scqy_key: "", //生产企业搜索框值
        kcbs: "", //库存标识id
        yxqbs: "", //有效期标识id
        activity_id: "", //活动标识id
        category_id: "", //商品分类标识id
        brand_id: "",
        sku: "",
        order_key: 101, //101综合 102销量 103价格 104库存

        sku_key: "",
        scqy: "",
      },
    };
  },
  created() {
    this.$api("goods.getGoodsFilter").then((res) => {
      let num = 0;
      res.data.forEach((e) => {
        if (e.list[0].value != 0) {
          e.list.unshift({
            name: "全部",
            value: 0,
            sel: 101,
          });
        } else {
          e.list[0].sel = 101;
        }

        // 是否显示更多
        num = 0;
        e.list.forEach((v) => {
          num += getTextWidth(v.name) + 40;
        });
        if (num < 1036) {
          e.showMore = false;
          e.fold = false;
        } else {
          e.showMore = true;
          e.fold = true;
        }
      });

      this.list = res.data;
    });
    this.p.kw = this.$store.getters.searchKey.kw;
    this.getScqy();
    this.getSku();
  },
  mounted() {
    // 监听 nav 搜索
    this.$bus.$on("updateSearch", this.updateSearch);
  },
  methods: {
    // 顶部搜索 kw 不带下面的搜索条件
    updateSearch() {
      this.p = {
        kw: "", //商品搜索框值
        scqy_key: "", //生产企业搜索框值
        kcbs: "", //库存标识id
        yxqbs: "", //有效期标识id
        activity_id: "", //活动标识id
        category_id: "", //商品分类标识id
        brand_id: "",
        sku: "",
        order_key: 101,

        sku_key: "",
        scqy: "",
      };
      let arr = DeepClone(this.list);
      arr.forEach((e) => {
        e.list.forEach((v) => {
          if (v.value == 0) v.sel = 101;
          else v.sel = 100;
        });
      });
      this.list = arr;
      this.getScqy();
      this.getSku();
    },
    getScqy() {
      this.$api("goods.getGoodsFilterscqy", {
        ...this.p,
        sku: this.p.sku_key,
      }).then((res) => {
        this.list6 = res.data;
        // 是否显示更多
        if (this.list6.length >= 10) {
          this.showMore1 = true;
        } else {
          let num = 0;
          this.list6.forEach((v) => {
            num += getTextWidth(v) + 40;
          });

          if (num < 1036) this.showMore1 = false;
          else this.showMore1 = true;
        }
      });
    },
    getSku() {
      this.$api("goods.getGoodsFiltersku", {
        ...this.p,
        scqy: this.p.scqy_key,
      }).then((res) => {
        this.list4 = res.data;

        // 是否显示更多
        if (this.list4.length >= 10) {
          this.showMore2 = true;
        } else {
          let num = 0;
          this.list4.forEach((v) => {
            num += getTextWidth(v) + 40;
          });

          if (num < 1036) this.showMore2 = false;
          else this.showMore2 = true;
        }
      });
    },
    clickItem(sub, key) {
      let arr = DeepClone(this.list);
      arr.forEach((e) => {
        if (e.key == key) {
          e.list.forEach((v) => {
            if (v.value == sub.value) {
              v.sel = 101;
              this.p[key] = v.value;
              if (e.key == "cate_id") {
                this.p.category_id = v.value;
              }
            } else {
              v.sel = 100;
            }
          });
        }
      });
      this.list = arr;
      this.getScqy();
      this.getSku();
      this.$emit("sendSearch", this.p);
    },
    clear(key) {
      this.p[key] = "";
      this.getScqy();
      this.getSku();
      this.$emit("sendSearch", this.p);
    },
    handleScqy(item) {
      this.p.scqy_key = item;
      this.isFold1 = true;
      this.$emit("sendSearch", this.p);
    },
    handleSku(item) {
      this.p.sku_key = item;
      this.isFold2 = true;
      this.$emit("sendSearch", this.p);
    },
    handleType(type) {
      this.currentType = type;
      this.$emit("sendType", type);
    },
    handleSort(id) {
      let order_desc = 101;
      this.list5.forEach((e) => {
        if (id == this.p.order_key) {
          if (id == e.id) {
            e.order_desc = e.order_desc == 101 ? 102 : 101;
            order_desc = e.order_desc;
          }
        } else {
          e.order_desc = 101;
        }
      });
      this.p.order_key = id;
      this.$emit("sendSearch", { ...this.p, order_desc });
    },
  },
  destroyed() {
    this.$bus.$off("updateSearch", this.updateSearch);
  },
};
</script>

<style lang="less" scoped>

.category {
  width: 100%;
  background-color: #fff;
  .top {
    height: 42px;
    width: 100%;
    border-bottom: 1px solid #eee;
    display: flex;
    line-height: 42px;
    p {
      font-size:14px;
      font-weight: bold;
      color: #333333;
    }
    .txt {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
    .red {
      color: #e91e10;
    }
    .fold {
      margin-left: auto;
      font-size: 12px;
      font-weight: 400;
      color: #333;
      .more_box {
        display: flex;
        cursor: pointer;
        margin-right: 5px;
      }
      .arrow {
        width: 13px;
        height: 8px;
        margin-left: 8px;
      }
    }
  }
  .cate_wrap {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    display: flex;
    min-height: 43px;
    line-height: 43px;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      margin-right: 40px;
      margin-left: 10px;
    }
    .str {
      margin-left: 20px;
      color: @themeColor;
      cursor: pointer;
    }
    .nomore {
      height: 43px;
    }
    .maxheight {
      height: 215px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(40, 44, 52, 0.1);
        border: 3px solid transparent;
        border-radius: 7px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(40, 44, 52, 0.1);
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
    .el-input {
      width: 200px;
    }
    .cate_item {
      margin-right: 40px;
      cursor: pointer;
      &:hover {
        color: @themeColor;
      }
      &.active {
        color: @themeColor;
      }
    }
    .textHeight {
      height: 43px;
    }
    .more_box {
      display: flex;
      cursor: pointer;
      margin-right: 5px;
    }
    .arrow {
      width: 13px;
      height: 8px;
      margin-left: 8px;
    }
  }
  .another {
    display: flex;
    height: 44px;
    align-items: center;
    color: #333;
    background: #f3f3f3;
    line-height: 44px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-right: 40px;
      margin-left: 10px;
    }
    .another_item {
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
      padding: 0 6px;
      span{
        font-size:14px
      }
      &.active {
        background: @themeColor;
        color: #fff;
      }
    }
    .type_item {
      cursor: pointer;
      margin-right: 18px;
      width: 66px;
      height: 30px;
     
      &.active {
        background: #ffffff;
        color: @themeColor;
      }
      .img {
        width: 14px !important;
        height: 14px !important;
        margin-right: 6px;
      }
    }
  }
}
</style>
